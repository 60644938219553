@layer mat {
  @import 'styles/theme';
}

@import "styles/colors";
@import "styles/fonts";
@import 'styles/info-box';
@import 'styles/mat-button-toggle-group';
@import 'styles/mat-button';
@import 'styles/mat-dialog';
@import 'styles/mat-radio-button';
@import 'styles/mat-input';
@import 'styles/split-pane';
@import "styles/table";
@import "styles/mat-expansion-panel";
@import "styles/mat-form-field";
@import "styles/mat-menu";
@import "styles/mat-tooltip";

body {
  margin: 0;
  padding: 0;
  overflow: auto;
}

main > router-outlet + * {
  display: block;
  height: 100%;
  overflow: auto;
  background-color: var(--white);
}

// Headlines, Text usw. BEGIN
h1,
.headline-gotham {
  @include H1_Headline_Gotham_Black;
  color: var(--OGE_blue);
}

h2,
.title-gotham {
  @include H2_Subheader_Gotham;
  color: var(--OGE_blue);
}

h3,
.subheader-gotham {
  @include H3_Headline_Gotham_Black;
  color: var(--OGE_blue);
}

h4,
.mat-expansion-panel-header-title,
.headline-gotham-black {
  @include H4_Headline_Gotham_Black;
  color: var(--OGE_blue);
}

p,
.oge-tiles,
.default-text,
.button-as-Link,
.mat-mdc-menu-content,
.body1-gotham {
  @include Body1_Gotham;
  color: var(--OGE_blue);
}

.mdc-label,
.body2-gotham {
  @include Body2_Gotham;
  color: var(--OGE_blue);
}

.mat-button-toggle-button,
.caption-gotham {
  @include Caption_Gotham;
  color: var(--OGE_blue);
}

.button-gotham {
  @include Button_Gotham;
  color: var(--OGE_blue);
}

.mini-light-gotham {
  @include Mini_Light_Gotham;
  color: var(--OGE_blue);
}


h5,
h6 {
  color: var(--OGE_blue);
  font-family: var(--OGE-gotham);
}

a {
  color: var(--copy);
  text-decoration: none;
}

.bold {
  font-weight: 700;
}

.hint-text {
  font-size: 10px;
  color: var(--copy);
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

// Headlines, Text usw. END

// TODO Check is necessary?
/* clears the 'X' from Internet Explorer */
input[type='search']::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

input[type='search']::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

/* clears the 'X' from Chrome */
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none;
}

// scrollbar BEGIN
::-webkit-scrollbar-track:horizontal,
::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar:horizontal,
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb:horizontal,
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: var(--copy);
}

// scrollbar END

// Loading Spinner BEGIN
.loading-container {
  display: grid;
  place-items: center;
  z-index: 99999;
  height: 100%;
  width: 100%;
  position: relative;
  background-color: white;
}

// Loading Spinner END


// TODO mat-dialog BEGIN ??
.mat-dialog-container {
  display: flex !important;
  position: relative;
  overflow: hidden;
  border-radius: 0.6rem !important;

  .mat-dialog-content {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;

    .mat-tab-group {
      flex-grow: 1;

      .mat-tab-body-wrapper {
        flex-grow: 1;
      }
    }
  }
}

mat-sidenav.mat-drawer.mat-sidenav.mat-drawer-opened {
  z-index: 12;
}

.mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgb(136, 165, 190);
  opacity: 0.2;
}

.mat-nav-list .mat-list-item:focus {
  background: transparent;
}

// ERROR BEGIN
.error-text {
  color: red !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.error {
  color: red !important;
}

.error-input {
  .mdc-notched-outline__leading,
  .mdc-notched-outline__notch,
  .mdc-notched-outline__trailing,
  mat-label {
    color: red !important;
    border-color: red !important;
  }
}

// ERROR END

/* Number fields without spinner */
.number-field-without-spinner {
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
}

.number-field-right-aligned input {
  text-align: right;

}

.button-as-Link {
  border: none;
  background-color: transparent;
  color: var(--OGE_mint2);
  text-decoration: none;
  hyphens: none !important;
  padding: 0;
  margin: 0;
  cursor: pointer;

  &.small {
    font-size: 12px;
  }

  &.medium {
    font-size: 16px;
  }

  &.default-color {
    color: var(--copy);
  }
}

/* Info Window google.maps.InfoWindow styling BEGIN */
button.gm-ui-hover-effect {
  display: none !important;
}

/* Info Window google.maps.InfoWindow styling END */

/* GOOGLE MAPS BEGIN */
.gm-style {
  background: var(--white);
}
// disable the blue border from google maps
.gm-style iframe + div {
  border: none!important;
}
.gm-style-moc .gm-style-mot {
  text-align: center;
}

.gm-bundled-control .gmnoprint {
  display: block;
}

.gmnoprint:not(.gm-bundled-control) {
  display: none;
}

.gm-style .gm-style-iw-c {
  box-shadow: var(--oge-box-shadow);
}

/* GOOGLE MAPS END */

/* Colors START */
.color-copy {
  color: var(--copy);
}

.color-blue {
  color: var(--OGE_blue);
}

.color-white {
  color: var(--white);
}

.color-pink {
  color: var(--OGE_pink3);
}

.color-locked {
  color: var(--OGE_blue_10);
}

/* Colors END */

/* App Styling START */
.dataset-icon {
  font-size: 12px;
  width: 12px !important;
  vertical-align: bottom !important;
}

.required-field {
  text-align: right;
}

.pin-element {
  position: relative;
  width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    position: absolute;
    color: black;
    font-size: 14px;
  }
}
/* App Styling END */

:root {
  font-family: #{$font_gotham_light};
  --OGE-gotham: #{$font_gotham};
  --OGE-gotham_light: #{$font_gotham_light};
  --OGE-gotham_black: #{$font_gotham_black};

  --OGE_blue1: #{$OGE_blue1};
  --OGE_blue2: #{$OGE_blue2};
  --OGE_blue3: #{$OGE_blue3};
  --OGE_blue4: #{$OGE_blue4};

  --OGE_mint1: #{$OGE_mint1};
  --OGE_mint2: #{$OGE_mint2};
  --OGE_mint3: #{$OGE_mint3};
  --OGE_mint4: #{$OGE_mint4};

  --OGE_green1: #{$OGE_green1};
  --OGE_green2: #{$OGE_green2};
  --OGE_green3: #{$OGE_green3};
  --OGE_green4: #{$OGE_green4};

  --OGE_pink1: #{$OGE_pink1};
  --OGE_pink2: #{$OGE_pink2};
  --OGE_pink3: #{$OGE_pink3};
  --OGE_pink4: #{$OGE_pink4};
  --OGE_pink_hover: #{$OGE_pink_hover};

  --OGE_blue: #{$OGE_blue};

  --OGE_yellow: #{$OGE_yellow};

  --OGE_error: #{$OGE_error};

  --OGE_blue_disabled: #{$OGE_blue_disabled};

  --bg_blue: #{$bg_blue};

  --bg_light_white: #{$bg_light_white};

  --bg_dark: #{$bg_dark};

  --OGE_blue_05: #{$OGE_blue_05};
  --OGE_blue_10: #{$OGE_blue_10};
  --OGE_blue_30: #{$OGE_blue_30};

  --copy: #{$copy};

  --white: #{$white};

  --nav_dark: #{$OGE_nav_dark};

  --nav_dark_30: #{$OGE_nav_dark_30};

  --nav_dark_50: #{$OGE_nav_dark_50};

  --oge-box-shadow: #{$oge-box-shadow};

  --footer_height: #{$footer_height};

  --toolbar_height: #{$toolbar_height};

  --separator: #{$separator};

  --oge-border-radius: 4px;
}
