
mat-form-field,
.mat-mdc-form-field {
  width: 100%;
}

mat-option {
  --mat-option-selected-state-label-text-color: var(--OGE_blue);
  --mat-option-label-text-color: var(--copy);
}

.cdk-overlay-container {
  .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full, .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
    background-color: var(--OGE_mint1);
  }
}

.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after, .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: var(--OGE_mint1);
}

mat-checkbox {
  --mat-icon-color: var(--OGE_blue_30);

  .mdc-label {
    display: flex;
    justify-content: center;

    mat-icon {
      padding-left: 5px;
    }
  }
}

mat-form-field {
  --mdc-outlined-text-field-disabled-label-text-color: var(--OGE_blue_disabled);
  --mdc-outlined-text-field-disabled-input-text-color: var(--copy);
  --mdc-outlined-text-field-label-text-color: var(--copy);
  --mdc-outlined-text-field-input-text-color: var(--OGE_blue);
  --mdc-outlined-text-field-error-label-text-color: var(--OGE_error);
  --mdc-outlined-text-field-error-text-color: var(--OGE_error);
  --mdc-outlined-text-field-focus-label-text-color: var(--OGE_mint2);
  --mdc-outlined-text-field-focus-text-color: var(--OGE_blue);
  --mat-select-enabled-trigger-text-color: var(--OGE_blue);
  --mdc-outlined-text-field-caret-color: var(--OGE_mint1);
  --mdc-filled-text-field-caret-color: var(--OGE_mint1);
  --mat-select-focused-arrow-color: var(--OGE_mint1);
  --mat-select-enabled-arrow-color: var(--OGE_mint1);
  --mat-select-disabled-arrow-color: var(--copy);
  --mat-select-disabled-trigger-text-color: var(--copy);
  --mdc-filled-text-field-disabled-input-text-color: var(--copy);
  --mat-form-field-state-layer-color: var(--white);

  .mdc-text-field--outlined {
    &.mdc-text-field--disabled {
      .mdc-notched-outline {
        .mdc-notched-outline__leading,
        .mdc-notched-outline__notch,
        .mdc-notched-outline__trailing {
          border-color: var(--OGE_blue_disabled);
          border-width: 2px;
        }
      }

      &:not(.mdc-text-field--focused):hover {
        .mdc-notched-outline {
          .mdc-notched-outline__leading,
          .mdc-notched-outline__notch,
          .mdc-notched-outline__trailing {
            border-color: var(--OGE_blue_disabled);
          }
        }
      }
    }

    &.mdc-text-field--invalid {
      &:not(.mdc-text-field--disabled) {
        .mdc-notched-outline {
          .mdc-notched-outline__leading,
          .mdc-notched-outline__notch,
          .mdc-notched-outline__trailing {
            border-color: var(--OGE_error);
          }
        }

        &.mdc-text-field--focused {
          .mdc-notched-outline {
            .mdc-notched-outline__leading,
            .mdc-notched-outline__notch,
            .mdc-notched-outline__trailing {
              border-color: var(--OGE_error);
            }
          }

          .mdc-notched-outline {
            .mdc-notched-outline__leading,
            .mdc-notched-outline__notch,
            .mdc-notched-outline__trailing {
              border-color: var(--OGE_error);
            }
          }
        }
      }
    }

    &:not(.mdc-text-field--disabled) {
      .mdc-notched-outline {
        .mdc-notched-outline__leading,
        .mdc-notched-outline__notch,
        .mdc-notched-outline__trailing {
          border-color: var(--OGE_blue_30);
          border-width: 2px;
        }
      }

      &.mdc-text-field--focused {
        .mdc-notched-outline {
          .mdc-notched-outline__leading,
          .mdc-notched-outline__notch,
          .mdc-notched-outline__trailing {
            border-color: var(--OGE_blue);
          }
        }
      }

      &:not(.mdc-text-field--focused):hover {
        .mdc-notched-outline {
          .mdc-notched-outline__leading,
          .mdc-notched-outline__notch,
          .mdc-notched-outline__trailing {
            border-color: var(--OGE_blue);
          }
        }
      }
    }
  }

  mat-hint {
    color: var(--OGE_blue_30);
  }
}

.mat-mdc-form-field-subscript-wrapper {
  height: 30px;
}

